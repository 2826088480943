import React from "react"
import LinkCustom from "../LinkCustom"
import { Collapse } from "antd"

import styles from "../../styles/modules/static-pages.module.scss"

const { Panel } = Collapse

const FAQs = ({ questions }) => (
  <>
    <div className="wrapper wrapper--left">
      <h2 className={`x-large-body-copy ${styles.title}`}>
        Frequently asked questions
      </h2>
      {questions.length && (
        <Collapse>
          {questions.map((question, index) => (
            <Panel
              header={question.question}
              key={index.toString()}
              className="header"
            >
              <p className={`body-copy ${styles.mediumParagraph}`}>
                {question.answer}
                {!!question.link && (
                  <span className={styles.blockLink}>
                    <LinkCustom
                      scope={question.link.scope}
                      size="small"
                      url={question.link.url}
                    >
                      {question.link.label}
                    </LinkCustom>
                  </span>
                )}
              </p>
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  </>
)

export default FAQs
